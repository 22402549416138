<template>
  <mdb-container class="container">
    <mdb-row center>
      <mdb-col sm="5">
        <mdb-card v-if="step === 0">
          <mdb-card-body>
            <!-- Material form login -->
            <form>
              <p class="h4 text-center mb-4">회원가입</p>
              <div class="grey-text">
                <mdb-input label="전화번호를 입력하세요." icon="phone" type="text" v-model="phone_number"
                           placeholder="'-' 없이 입력"/>
              </div>
              <div class="grey-text">
                <mdb-input label="인증코드를 입력하세요." icon="lock" type="password" v-model="auth_code" placeholder="인증번호 6자리"/>
              </div>
              <div class="text-center">
                <mdb-btn @click="handlePhoneNumberAuth" v-if="!request_code">인증 요청</mdb-btn>
                <mdb-btn @click="handleAuthCode" v-if="request_code">인증 확인</mdb-btn>
              </div>
              <div id="recaptcha-container"></div>
            </form>
            <!-- Material form login -->
          </mdb-card-body>
        </mdb-card>

        <mdb-card v-if="step === 1">
          <mdb-card-body>
            <!-- Material form login -->
            <form>
              <p class="h4 text-center mb-4">회원가입</p>
              <div class="grey-text">
                <mdb-input label="전화번호를 입력하세요." icon="phone" type="text" v-model="phone_number" disabled placeholder="'-' 없이 입력"/>
                <mdb-input label="이메일을 입력하세요." icon="envelope" type="email" v-model="email_id"/>
              </div>
              <div class="text-center">
                <mdb-btn @click="signUp">가입완료</mdb-btn>
              </div>
            </form>
            <!-- Material form login -->
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>
<script>
import {mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn, mdbCardBody, mdbCard} from 'mdbvue';
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: 'UserLogin',
  data() {
    return {
      email_id: '',
      phone_number: '',
      auth_code: '',
      request_code: false,
      step: 0,
      uid: '',
      login_number: '',
    }
  },
  components: {
    mdbInput,
    mdbBtn,
    mdbCardBody,
    mdbCard, mdbContainer, mdbRow, mdbCol,
  },
  mounted() {
    this.reCaptcha();
  },
  methods: {
    reCaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response)
        }
      });
    },
    /**
     * firebase PhoneNumber 인증 요청
     */
    handlePhoneNumberAuth() {
      const self = this;
      const tlno = self.phone_number.trim();
      self.login_number = self.phone_number.trim();

      firebase.auth().languageCode = 'ko';
      firebase.auth().signInWithPhoneNumber('+82' + tlno, window.recaptchaVerifier)
          .then((confirmationResult) => {
            alert('인증 요청을 했습니다.');
            self.request_code = true;

            window.confirmationResult = confirmationResult;
          })
          .catch((error) => {
            self.firebaseError(error)
          })
    },

    /**
     * 인증코드 확인
     */
    handleAuthCode() {
      const self = this;
      const authCode = self.auth_code.trim();

      window.confirmationResult.confirm(authCode)
          .then((result) => {
            alert('인증 완료 되었습니다.');
            console.log(result.user, result.user.uid);
            self.step = 1;

            self.firePhoneNumber();
          })
          .catch((error) => {
            self.firebaseError(error)
          })
    },
    /**
     * firebase 에러 처리
     * @param error
     */
    firebaseError(error) {
      if ('auth/invalid-verification-code' === error.code) {
        alert('인증번호가 유효하지 않습니다.');
      } else if ('auth/session-expired' === error.code) {
        alert('인증번호가 만료되었습니다.');
      } else if ('auth/too-many-requests' === error.code) {
        alert('잠시 후 다시 시도해 주세요.');
      } else if ('Error: The email address is badly formatted.' === error.code) {
        alert('이메일은 필수입니다.');
      } else {
        console.log(error);
      }
    },
    /**
     * firebase 로그인한 계정 파기
     */
    firePhoneNumber() {
      const user = firebase.auth().currentUser;
      user.delete().then(() => {
        // User deleted.
      }).catch(() => {
        // An error happened.
      });
    },

    logout() {
      const self = this;
      firebase.auth().signOut().then(() => {
        self.$store.commit('setLogout');
        self.$router.replace('/');
      }).catch((error) => {
        console.log(error);
      });
    },

    /**
     * firebase 실제가입
     * 패스워드를 전화번호로 하며
     * 이메일을 계정으로 함
     * 고객은 전화번호가 계정으로 생각
     */
    signUp() {
      const self = this;
      const db = firebase.firestore();
      const auth = firebase.auth();

      const id = self.email_id;
      const password = self.login_number;

      auth.createUserWithEmailAndPassword(id, password)
          .then((result) => {
            console.log(result.user.uid)
            result.user.updateProfile({displayName: "USER"})
            const userInfo = {
              email: id,
              mobile : self.login_number,
              admin: false,
              reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
            }
            db.collection('user').doc(result.user.uid).set(userInfo)
                .then(() => {
                  self.$router.replace('/');
                })
                .catch((err) => {
                  alert(err);
                })
          })
          .catch((err) => {
            // 실패 후 실행할 코드
            self.firebaseError(err)
          })
    }
  },

}
</script>

<style scoped>
.container {
  margin-top: 150px;
}

.grey-text{
  font-family: AppleSDGothicNeo,serif;
}

.text-center{
  font-family: AppleSDGothicNeo,serif
}
</style>
